<template>
  <dx-util-popup
    ref="companyEditPopupRef"
    height="auto"
    width="900"
    :show-close-button="true"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :title="popupTitle"
    @shown="onShownCompanyInfo"
    @hidden="onHiddenCompanyInfo"
  >
    <dx-util-scroll-view width="100%" height="100%">
      <div>
        <dx-util-tab-panel :animation-enabled="true" :selected-index="selectedTabIndex">
          <dx-util-panel-item v-if="true" title="General Info" icon="bi bi-info">
            <template #default>
              <div style="min-height: 500px;">
                <form class="p-1" @submit="handleSubmit">
                  <dx-util-form ref="companyFormRef" :form-data="companyForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="companyDataForm">
                    <dx-util-group-item :col-count="2">
                      <dx-util-item data-field="accountNo" editor-type="dxTextBox" />
                      <dx-util-item data-field="suiteNo" editor-type="dxTextBox" />
                      <dx-util-item data-field="status" editor-type="dxSelectBox" :editor-options="formStatusOptions" :disabled="true" />
                      <dx-util-item data-field="monthlyItemVolume" editor-type="dxSelectBox" :editor-options="formMonthlyVolumeOptions" />
                    </dx-util-group-item>
                    <dx-util-item data-field="name" editor-type="dxTextBox">
                      <dx-util-required-rule message="Name is required" />
                    </dx-util-item>
                    <dx-util-item :editor-options="{ height: 200 }" data-field="notes" editor-type="dxTextArea" />
                  </dx-util-form>
                </form>
                <div class="text-right p-1">
                  <dx-util-button type="danger" text="Cancel" :width="100" class="mr-1" @click="onCancel" />
                  <dx-util-button type="success" text="Save" :width="100" @click="handleSubmit" />
                </div>
              </div>
            </template>
          </dx-util-panel-item>
          <dx-util-panel-item title="Staff Assignment" icon="bi bi-people">
            <template #default>
              <dx-util-scroll-view height="100%">
                <div class="p-1" style="min-height: 500px;">
                  <div class="d-flex text-right py-half">
                    <dx-util-select-box v-model="selectedDepartment" :data-source="departments" display-expr="departmentName" value-expr="id" class="flex-grow-1 mr-half" @valueChanged="getDepartmentStaff" />
                    <dx-util-select-box v-model="selectedStaff" :data-source="departmentStaffList" display-expr="fullName" value-expr="id" class="flex-grow-1 mr-half" />
                    <dx-util-button text="Assign" icon="add" type="success" @click="assignStaff" />
                  </div>
                  <div>
                  </div>
                  <div v-for="staff in assignedStaff" :key="staff.key" class="d-flex m-1 align-items-center">
                    <div class="flex-grow-1">
                      {{ staff.departmentName }} - {{ staff.fullName }}
                    </div>
                    <dx-util-button icon="remove" type="danger" @click="removeStaffAssignment(staff)" />
                  </div>
                </div>
              </dx-util-scroll-view>
            </template>
          </dx-util-panel-item>
          <dx-util-panel-item title="Working Days" icon="bi bi-people">
            <template #default>
              <div style="min-height: 500px;" class="pt-1">
                <div v-for="day in weekDays" :key="day" class="p-half d-flex">
                  <div
                    style="width: 160px; margin: 4px;"
                    class="bg-primary px-1 py-1 rounded-sm"
                  >
                    {{ day }}
                  </div>
                  <div v-if="workingDays[day]">
                    <dx-util-tag-box
                      id="store"
                      v-model="workingDays[day].taskTypes"
                      placeholder="Select task types"
                      class="w-100 py-half"
                      style="min-width: 300px;"
                      :items="allActions"
                      :show-selection-controls="true"
                      :accept-custom-value="true"
                      @value-changed="onValueChanged($event, day)"
                      @customItemCreating="onCustomItemCreating"
                    />
                  </div>
                </div>
                <div class="px-2 py-1 text-right">
                  <dx-util-button text="Save" type="success" style="width: 120px" @click="saveWorkingDays" />
                </div>
              </div>
            </template>
          </dx-util-panel-item>
        </dx-util-tab-panel>
      </div>
    </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import companyService from '@/http/requests/company/companyService'
import departmentService from '@/http/requests/system/departmentService'
import { getCompanySizeList } from '@/enums/companySize.enum'
// import userManagementService from '@/http/requests/system/userManagementService'

const initialCompanyForm = {
  id: null,
  accountNo: '',
  suiteNo: '',
  name: '',
  monthlyItemVolume: '',
  status: '',
  notes: '',
}
export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      companyInfoTitle: '',
      selectedTabIndex: 0,
      companySize: 0,
      sizes: getCompanySizeList(),
      allActions: [
        'FBA', 'FBM', 'Receiving', 'Shipment', 'Problem', 'WFS',
      ],
      selectedActions: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      workingDays: {
        Monday: {
          taskTypes: [],
        },
        Tuesday: {
          taskTypes: [],
        },
        Wednesday: {
          taskTypes: [],
        },
        Thursday: {
          taskTypes: [],
        },
        Friday: {
          taskTypes: [],
        },
        Saturday: {
          taskTypes: [],
        },
        Sunday: {
          taskTypes: [],
        },
      },
      departments: [],
      departmentStaffList: [],
      selectedDepartment: '',
      selectedStaff: [],
      assignedStaff: [],
      companyForm: { ...initialCompanyForm },
      popupTitle: '',
      statusOptions: [
        { name: 'Status (All)', id: '' },
        { name: 'Active', id: 'active' },
        { name: 'Passive', id: 'passive' },
        { name: 'Pending', id: 'pending' },
      ],
    }
  },
  computed: {
    companyInfoPopup() {
      return this.$refs.companyEditPopupRef.instance
    },
    formStatusOptions() {
      return {
        dataSource: this.statusOptions,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        dropDownOptions: {
          height: 150,
        },
      }
    },
    formMonthlyVolumeOptions() {
      return {
        dataSource: this.sizes.filter(el => el.value !== 0),
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        dropDownOptions: {
          height: 150,
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.companyInfoPopup.show()
        this.getCompanyDataById()
        this.getAllDepartments()
        this.getCalendarByCompanyId()
      },
    },
  },
  methods: {
    onShownCompanyInfo() {
      this.popupTitle = 'Company Update'
      this.getAllAssignedStaff()
    },
    onHiddenCompanyInfo() {
      this.companyForm = { ...this.initialCompanyForm }
      this.selectedStaff = null
      this.selectedDepartment = null
      this.assignedStaff = []
      this.workingDays = {
        Monday: { taskTypes: [] },
        Tuesday: { taskTypes: [] },
        Wednesday: { taskTypes: [] },
        Thursday: { taskTypes: [] },
        Friday: { taskTypes: [] },
        Saturday: { taskTypes: [] },
        Sunday: { taskTypes: [] },
      }
      this.companySize = 0
    },
    onCancel() {
      this.companyForm = { ...this.initialCompanyForm }
      this.companyInfoPopup.hide()
    },
    onCustomItemCreating(args) {
      const newValue = args.text
      const exists = this.allActions.some(item => item === newValue)
      if (!exists) {
        this.allActions.push(newValue)
      }
    },
    async handleSubmit(e) {
      const form = this.$refs.companyFormRef.instance
      const validationResult = form.validate()
      if (validationResult.isValid) {
        delete this.companyForm.status
        await companyService.upsert(this.companyForm)
        this.companyInfoPopup.hide()
        this.$emit('update-company')
      }
    },
    onDepartmentSelected(e) {
      this.getDepartmentStaff(e.value)
    },
    async assignStaff() {
      if (this.selectedStaff === null || this.selectedDepartment === null) {
        Notify.warning('You need to select department and user')
        return
      }
      await departmentService.associateCustomerCompanyWarehouseUser(this.selectedDepartment, this.companyId, this.selectedStaff, true)
      this.getAllAssignedStaff()
    },
    async removeStaffAssignment(staff) {
      const { departmentId, id } = staff
      await departmentService.associateCustomerCompanyWarehouseUser(departmentId, this.companyId, id, false)
      this.getAllAssignedStaff()
    },
    getAllAssignedStaff() {
      this.assignedStaff = []
      const allDepartments = 0
      departmentService.getDepartmentUsersAssociateCustomerCompany(allDepartments, this.companyId).then(result => {
        this.assignedStaff = result
      })
    },
    getAllDepartments() {
      departmentService.getTenantDepartments().then(result => {
        this.departments = result
      })
    },
    getDepartmentStaff(e) {
      const departmentId = e.value
      departmentService.getDepartmentUsers(departmentId).then(result => {
        this.departmentStaffList = result
      })
    },
    async saveWorkingDays() {
      const payload = {
        id: this.currentCalendar?.id || null,
        workingDayTask: this.workingDays,
      }
      await companyService.upsertCalendar(this.companyId, payload)
      this.companyInfoPopup.hide()
      this.$emit('update-company')
    },
    onValueChanged(e, day) {
      const tasks = {
        taskTypes: e.value,
      }
      this.workingDays[day] = { ...this.workingDays[day], ...tasks }
    },
    async getCalendarByCompanyId() {
      const response = await companyService.getCalendarByCompanyId(this.companyId)
      this.currentCalendar = response[0]
      if (this.currentCalendar && this.currentCalendar.id) {
        this.workingDays = { ...this.currentCalendar?.days }
      }
    },
    async getCompanyDataById() {
      const response = await companyService.fetchById(this.companyId)
      const {
        id, accountNo, suiteNo, name, monthlyItemVolume, status, notes,
      } = response.data
      this.companyForm = {
        id, accountNo, suiteNo, name, monthlyItemVolume, status, notes,
      }
    },

  },
}
</script>
